<template>
  <div class="about-us-bg">
    <div class="bg-1">
      <div class="bg-2">
        <div class="text-slider">
          <div class="controls" v-touch:swipe="handleSwipe">
            <div @click="prevSlide" class="arrow left">
              <img src="/img/left-arrow.svg" />
            </div>

            <div class="slider-container"  data-aos="zoom-in"  data-aos-delay="250">
              <transition name="fade" mode="out-in" class="custom-fade">
                <div :key="currentIndex" class="slider-content">
                  <p class="h1 mt-2 about-us-h1">
                    {{ aboutus[currentIndex].title }}
                  </p>
                  <p class="mt-4 about-us-description">
                    {{ aboutus[currentIndex].subtitle }}
                  </p>
                  <p class="mt-4 about-us-description">
                    {{ aboutus[currentIndex].subtitle2 }}
                  </p>
                </div>
              </transition>
            </div>

            <div @click="nextSlide" class="arrow right">
              <img src="/img/right-arrow.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  components: { VueSlickCarousel },
  data: () => ({
    aboutus: [
      {
        title: "ABOUT US",
        subtitle: ` At Sapphire Software, we pride ourselves on our commitment to
            excellence and customer satisfaction. Our team of skilled and
            experienced professionals works diligently to understand the unique
            needs and challenges of our clients, providing tailored software
            solutions that drive efficiency, productivity, and growth.`,

        subtitle2: ` We prioritize delivering exceptional value to our clients. Our
            solutions are known for their reliability, scalability, and
            user-friendly interfaces, empowering businesses to stay ahead of the
            competition in today's rapidly evolving digital world.`,
      },
      {
        title: "Mission",
        subtitle: `   To empower businesses with innovative and reliable technology
            solutions that drive efficiency, productivity, and growth. We strive
            to be a trusted partner, delivering exceptional value to our clients
            through unparalleled expertise and customer-centric services.`,
      },
      {
        title: "Vision",
        subtitle: ` To become leading global technology solution provider,
            revolutionizing industries and transforming businesses through
            advanced technologies and strategic partnerships. We aim to foster a
            world where technology empowers organizations to reach new heights
            and make a positive impact on society.`,
      },
    ],
    currentIndex: 0,
  }),

  mounted() {
    AOS.init();
  },
  methods: {
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.aboutus.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.aboutus.length) % this.aboutus.length;
    },
    handleSwipe(e) {
      if (e === "left" && this.currentIndex < this.aboutus.length - 1) {
        this.currentIndex = (this.currentIndex + 1) % this.aboutus.length;
      } else if (e === "right" && this.currentIndex > 0) {
        this.currentIndex =
          (this.currentIndex - 1 + this.aboutus.length) % this.aboutus.length;
      }
    },
  },
};
</script>
<style scoped>
.about-us-bg {
  background: linear-gradient(96.44deg, #1f2c64 21.37%, #12172f 68.12%);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-us-h1 {
  font-style: normal;
  font-weight: 800;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
  font-size: clamp(32px, 5vw, 50px);
}

.about-us-description {
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
}

.bg-1 {
  background-image: url("/img/group1.svg");
  background-position: top left;
  background-repeat: no-repeat;
}

.bg-2 {
  background-image: url("/img/group2.svg");
  background-position: top right;
  background-repeat: no-repeat;
}
.text-slider {
  margin: auto;
  max-width: 1200px;
}

.slider-container {
  overflow: hidden;
}

.slider-content {
  text-align: center;
  padding: 50px 20px;
}

.controls {
  display: flex;
  align-items: center;
  height: 500px;
}

.arrow {
  cursor: pointer;
  user-select: none;
  display: block;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 800px) {
  .controls {
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  .about-us-description {
    font-size: 15px;
    line-height: 20px;
  }

  .arrow {
    display: none;
  }

  .bg-1 {
    background-image: url("");
  }
  .bg-2 {
    background-image: url("");
  }
}
</style>
